// components/CookieConsentModal.js
'use client';
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Button } from "@heroui/react";

const ModalContainer = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  boxShadow: '0 -5px 15px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 9999,

  [theme.breakpoints.up('sm')]: {
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    maxWidth: 600,
    borderRadius: 16,
  },
}));

const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '1rem',
});

const CookieEmoji = styled('span')({
  marginRight: '8px',
  fontSize: '1.2em',
});

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: '0.9rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)({
  width: '100%',

  '@media (min-width: 600px)': {
    width: 'auto',
  },
});

const CookieConsentModal = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setOpen(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <ModalContainer
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          <TitleWrapper>
            <CookieEmoji role="img" aria-label="cookie">
              🍪
            </CookieEmoji>
            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
              Votre vie privée est importante
            </Typography>
          </TitleWrapper>
          <Description variant="body1">
            Nous utilisons des cookies pour améliorer votre expérience sur notre
            site. Certains sont essentiels pour le fonctionnement du site,
            tandis que d'autres nous aident à l'améliorer et à personnaliser
            votre navigation.
          </Description>
          <ButtonContainer>
            <StyledButton auto color="primary" onClick={handleAccept}>
              Accepter tous les cookies
            </StyledButton>
            <StyledButton auto color="secondary" onClick={handleDecline}>
              Cookies essentiels uniquement
            </StyledButton>
          </ButtonContainer>
        </ModalContainer>
      )}
    </AnimatePresence>
  );
};

export default CookieConsentModal;
