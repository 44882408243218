import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface INotFoundProps {
}

export default function NotFound (props: INotFoundProps) {
  return (
    <div className=' flex flex-col justify-center items-center'>
        <FontAwesomeIcon className=' text-3xl text-gray-400 mb-4' icon={faMagnifyingGlass} />
        <span className=' font-bold text-3xl'>Aucun produit trouvé</span>

        <span className='mt-5 m-24 text-center'>
        Nous n'avons pas trouvé de résultat pour votre recherche. Essayez avec un autre mot-clé ou essayez de réduire votre recherche.
        </span>
      
    </div>
  );
}
