'use client';
import React, { FC, useRef, useEffect, useState } from 'react';
import Link from 'next/link';

interface CategoryCardProps {
    name: string;
    color: string;
    image: string;
    url: string;
}

const CategoryCard: FC<CategoryCardProps> = ({ name, color, image, url }) => (
    <Link href={url} passHref>
        <div
            className={`relative flex flex-col items-center justify-center rounded-lg shadow-lg w-[180px] h-[160px] flex-shrink-0 transform transition-all duration-300 hover:scale-105 ${color}`}
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="absolute top-4 text-white bg-black bg-opacity-60 px-4 py-2 rounded-md">
                {name}
              
            </div>
        </div>
    </Link>
);

const categories = [
    {
        name: "Chaussures Homme",
        color: "bg-red-500",
        image: "/images/sneaker.png",
        url: "/products-listing?productCategory=Mode+homme+-+Chaussures",
    },
    {
        name: "Bijoux",
        color: "bg-yellow-500",
        image: "/images/jewell.png",
        url: "/products-listing?productCategory=Bijoux+et+montres",
    },
    {
        name: "Téléphones",
        color: "bg-cyan-500",
        image: "/images/iphone16promax.png",
        url: "/products-listing?productCategory=Téléphones+",
    },
    {
        name: "Habits Homme",
        color: "bg-orange-500",
        image: "/images/for-men.png",
        url: "/products-listing?productCategory=Mode+homme+-+Vêtements",
    },
    {
        name: "Chaussures Femme",
        color: "bg-cyan-500",
        image: "images/women-heels.png",
        url: "/products-listing?productCategory=Mode+femme+-+Chaussures",
    },

    {
        name: "Jeux Video",
        color: "bg-red-500",
        image: "/images/nintendo-switch.png",
        url: "/products-listing?productCategory=Jeux+vidéo",
    },
    {
        name: "Accessoires Electroniques",
        color: "bg-yellow-100",
        image: "/images/speaker.png",
        url: "/products-listing?productCategory=Accessoires+électroniques",
    },
];

const CategoriesCarousel: FC = () => {

    const infiniteCategories = [...categories, ...categories]; // Duplicating categories for infinite scroll
    const carouselRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            const handleScroll = () => {

                if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) {
                    carousel.scrollLeft = 0;
                }
            };

            // Auto-scroll
            const interval = setInterval(() => {
                if (!isDragging && carousel) {
                    carousel.scrollLeft += 1; // Adjust scroll speed here
                    handleScroll();
                }
            }, 32);

            return () => clearInterval(interval);
        }
    }, [isDragging]);

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        const carousel = carouselRef.current;
        if (carousel) {
            setStartX(e.pageX - carousel.offsetLeft);
            setScrollLeft(carousel.scrollLeft);
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        const carousel = carouselRef.current;
        if (carousel) {
            const x = e.pageX - carousel.offsetLeft;
            const walk = (x - startX) * 2; // Adjust scroll speed for dragging
            carousel.scrollLeft = scrollLeft - walk;
        }
    };

    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeave = () => setIsDragging(false);

    const handleTouchStart = (e: React.TouchEvent) => {
        setIsDragging(true);
        const carousel = carouselRef.current;
        if (carousel) {
            setStartX(e.touches[0].pageX - carousel.offsetLeft);
            setScrollLeft(carousel.scrollLeft);
        }
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging) return;
        const carousel = carouselRef.current;
        if (carousel) {
            const x = e.touches[0].pageX - carousel.offsetLeft;
            const walk = (x - startX) * 2; // Adjust scroll speed for dragging
            carousel.scrollLeft = scrollLeft - walk;
        }
    };

    const handleTouchEnd = () => setIsDragging(false);


    return (
        <section className="bg-white py-2 overflow-hidden">
            <h2 className="text-3xl font-bold text-center my-4 text-gray-800">
                Découvrez Nos Catégories Top
            </h2>
            <div
                className="relative w-full overflow-x-hidden cursor-grab active:cursor-grabbing"
                ref={carouselRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="flex space-x-4">
                    {infiniteCategories.map((category, index) => (
                        <CategoryCard
                            key={index}
                            name={category.name}
                            color={category.color}
                            image={category.image}
                            url={category.url}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CategoriesCarousel;