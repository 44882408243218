'use client';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { tabTexts } from '../../../Utils/home-page-content/TabTexts';
import ProductSearchForm from './search-form/ProductSearchForm';
import ServicesSearchForm from './search-form/ServicesSearchForm';

interface HeroSectionProps {
  searchFilterData: {
    cityList: any[];
    serviceCategoriesList: any[];
    productCategoriesList: any[];
  };
}

const HeroSection = ({ searchFilterData }: HeroSectionProps) => {
  type TabKey = 'services' | 'products';
  const [activeTab, setActiveTab] = useState<TabKey>('products');
  const { title, subtitle, backgroundImage } = tabTexts[activeTab];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Reset image index when tab changes
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [activeTab]);

  // Image cycling effect for background image
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === backgroundImage.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [activeTab, backgroundImage.length]);

  const handleTabClick = (tab: TabKey) => {
    setActiveTab(tab);
  };

  const renderActiveForm = () => {
    switch (activeTab) {
      case 'services':
        return (
          <ServicesSearchForm
            cityList={searchFilterData.cityList}
            serviceCategoriesList={searchFilterData.serviceCategoriesList}
          />
        );
      case 'products':
        return (
          <ProductSearchForm
            cityList={searchFilterData.cityList}
            categoryList={searchFilterData.productCategoriesList}
          />
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.75 }}
      className="hero-section bg-cover bg-center bg-no-repeat mt-10"
      style={{ backgroundImage: backgroundImage[currentImageIndex] }}
    >
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col md:flex-row items-center gap-8 my-16">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className="flex-1 md:order-2 bg-black bg-opacity-50 p-6 rounded-lg shadow-lg backdrop-blur-sm">
            <motion.h1
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: 'easeInOut', duration: 0.75 }}
              className="text-[28px] font-bold text-white mb-4">{title}</motion.h1>
            <motion.h3
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: 'easeInOut', duration: 0.75 }}
              className="text-white font-bold text-[18px]">{subtitle}</motion.h3>
          </motion.div>
          <div className="flex-1 md:order-1">
            <div className="search-bar my-5">
              <div className="tabs flex  mb-5">
                <button
                  onClick={() => handleTabClick('products')}
                  className={`text-sm font-bold ${activeTab === 'products' ? 'bg-gray-900 text-white' : 'bg-gray-300 hover:bg-gray-400'} p-2  transition-all duration-300 w-full sm:w-auto pointer rounded-l-md`}
                >
                  Produits
                </button>
                <button
                  onClick={() => handleTabClick('services')}
                  className={`text-sm font-bold ${activeTab === 'services' ? 'bg-gray-900 text-white' : 'bg-gray-300 hover:bg-gray-400'} p-2  transition-all duration-300 w-full sm:w-auto pointer rounded-r-md`}
                >
                  Services
                </button>
              </div>
              {renderActiveForm()}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HeroSection;