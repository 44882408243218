import { BaseService } from "../../BaseService";

interface IProduct {
  id: number;
  name: string;
  price: string;
  images: { url: string }[];
  seller: { username: string };
  featured: boolean;
  slug: string;
  city?: { name: string };
}

interface ProductsResponse {
  data: IProduct[];
  meta: {
    total: number;
    per_page: number;
  };
}

const ProductListingService = BaseService.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<ProductsResponse, { city?: string; productType?: string; page?: number }>({
      query: ({ city, productType, page }) => {
        let queryString = '/products/?populate=*&';

        if (city) {
          queryString += `filters[city][name][$eq]=${city}&`;
        }

        if (productType) {
          queryString += `filters[subcategory][name][$eq]=${productType}&`;
        }

        if (page) {
          queryString += `pagination[page]=${page}&`;
        }

        return queryString;
      },
    }),


    showProductBySlug: build.query({
      query: ({ slug }) => {
        let queryString = '/products/?populate=*&';

        if (slug) {
          queryString += `filters[slug][$eq]=${slug}&`;
        }

        return queryString;
      },
    }),

    getProductListingCategories: build.query({
      query: () => "categories/?populate=*",
    }),

    getProductSubCategories: build.query<any, void>({
      query: () => "subcategories",
    }),

    showProduct: build.query<IProduct, number>({
      query: (id) => `products/${id}?populate=*`,
    }),

    createProductListing: build.mutation<any, any>({
      query: (data) => ({
        url: '/products?populate=*',
        method: 'POST',
        body: data,
      }),
    }),

    updateProductListing: build.mutation<any, { id: number; data: any }>({
      query: ({ id, data }) => ({
        url: `/products/${id}?populate=*`,
        method: 'PUT',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }),
    }),

    deleteProductListing: build.mutation<any, number>({
      query: (id) => ({
        url: `/products/${id}?populate=*`,
        method: 'DELETE',
      }),
    }),

    getProductBySlug: build.query<IProduct, string>({
      query: (slug) => `/products/?filters[slug][$eq]=${slug}&populate=*`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useShowProductQuery,
  useGetProductsQuery,
  useGetProductListingCategoriesQuery,
  useCreateProductListingMutation,
  useUpdateProductListingMutation,
  useDeleteProductListingMutation,
  useGetProductSubCategoriesQuery,
  useShowProductBySlugQuery
} = ProductListingService
