
export interface ITabContentItemProps {
    title:string;
    value:string;
}

export default function TabContentItem (props: ITabContentItemProps) {
  return (
   
          <div className="flex text-small  mb-1 font-extralight ">
          <span className="mr-4 ">
            {props.title}:
          </span>

          <span>
            {props.value}
          </span>
          </div>

          
  );
}
