import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import Image from 'next/image';

export interface IProductCardProps {
  onClick?: () => void;
  name: string;
  price: string;
  images: any;
  seller: string;
  featured: boolean;
  slug: string;
  city: string;
}

export default function ProductCard(props: IProductCardProps) {
  return (
    <motion.div
      onClick={props.onClick}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
      className="relative cursor-pointer bg-gray-100 rounded-xl pb-3" // Added relative positioning
    >
      {props.featured && (
        <small className="absolute top-2 right-2 z-10 bg-gray-900 text-white text-xs font-bold px-2 py-1 rounded-lg shadow-lg">
          Deal Spécial
        </small>
      )}
      <Image
        src={props.images[0]?.url}
        alt={props.name}  // Added meaningful alt text
        width={500}
        height={300}
        className="mb-1 rounded-xl h-40 w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
      />
      <div className="px-2 flex flex-col">
        <span className="mb-2 text-small font-semibold">{props.name}</span>
        <span className="font-bold text-small text-red-500">
          $ {new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Number(props.price))}
        </span>

        <span className="mb-2 mt-1 text-small font-extralight">{props.seller}</span>
        <span className=" mt-1 text-small font-extralight"> <FontAwesomeIcon icon={faLocationDot} />  {props.city}</span>

      </div>
    </motion.div>
  );
}

