'use client';
import CustomSelect from '@/app/components/forms/form-inputs/CustomSelect';
import { Button, Spinner } from "@heroui/react";
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

interface ILocationProps {
  id: number;
  name: string;
  icon: undefined | string;
}

interface ProductSearchFormProps {
  cityList: any[];
  categoryList: any[];
  // propertyTypes: any[];
}

export default function ProductSearchForm({
  cityList,
  categoryList,
}: ProductSearchFormProps) {

  const router = useRouter();

  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<ILocationProps | null>(null);
  const [category, setCategory] = useState<ILocationProps | null>(null);

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    setLoading(true);

    if (!location && !category) {
      console.error('Please select location and property type.');
      setLoading(false);
      return;
    }

    const query = new URLSearchParams();
    if (location) query.append('location', location.name);
    if (category) query.append('productCategory', category.name);
 
    setLoading(false);
    router.push(`/products-listing?${query.toString()}`
    );
   
  };

  return (
    <motion.form
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
      onSubmit={handleSearch}
      className="space-y-3 min-w-[350px] min-h-[220px] flex flex-col gap-3"
    >
      {!loading ? (
        <>
          <div className="mb-4 w-full">
            <CustomSelect
              name="location"
              placeholder="Sélectionnez une ville"
              value={location}
              handleChange={(value: ILocationProps) => setLocation(value)}
              optionData={cityList}
            />
          </div>
          <div className="mb-4 w-full">
            <CustomSelect
              name="product_category"
              placeholder="Quel type de produit recherchez-vous?"
              value={category}
              handleChange={(value: ILocationProps) => setCategory(value)}
              optionData={categoryList}
            />
          </div>

        </>
      ) : (
        <div className="flex flex-col gap-2 items-center justify-center h-full min-h-[60%]">
          <h1>searching...</h1>
          <Spinner color="default" labelColor="foreground" />
        </div>
      )}
      <Button
        isLoading={loading}
        type="submit"
        className="text-white bg-black text-sm h-12"
      >

        {!loading ? 'Rechercher' : 'Loading...'}
      </Button>
    </motion.form>
  );
}
