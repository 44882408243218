'use client';
import { store } from '@/redux/store';
import {
  faCartShopping,
  faHandshakeAngle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@heroui/react";
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';

interface MenuItem {
  id: number;
  name: string;
  path: string;
  icon?: React.ReactNode;
}

const Menu: React.FC = () => {
  const router = useRouter();
  const [user, setUser] = useState<any | null>(null);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: 'Ajouter Produit',
      path: '/create-product',
      icon: (
        <p className="p-1 rounded-full">
          <FontAwesomeIcon icon={faCartShopping} className="text-2xl" />
        </p>
      ),
    },
    // {
    //   id: 2,
    //   name: 'Ajouter Propriété',
    //   path: '/create-property',
    //   icon: (
    //     <p className="p-1 rounded-full">
    //       <FontAwesomeIcon icon={faHouse} className="text-2xl" />
    //     </p>
    //   ),
    // },
    // {
    //   id: 3,
    //   name: 'Ajouter Véhicule',
    //   path: '/create-vehicle',
    //   icon: (
    //     <p className="p-1 rounded-full">
    //       <FontAwesomeIcon icon={faCar} className="text-2xl" />
    //     </p>
    //   ),
    // },
    {
      id: 4,
      name: 'Ajouter Service',
      path: '/create-service',
      icon: (
        <p className="p-1 rounded-full">
          <FontAwesomeIcon icon={faHandshakeAngle} className="text-2xl" />
        </p>
      ),
    }
  ];

  useEffect(() => {
    const userData = store.getState()?.authSlice?.user ?? {};
    setUser(userData);
    setAuthenticated(userData && userData.email);
  }, []);

  const handleItemClick = (path: string) => {
    if (isAuthenticated) {
      router.push(path);
    } else {
      onOpen();
    }
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.75 }}
      className="w-full hidden lg:block md:w-auto py-2 rounded-full shadow-sm hover:shadow-md transition cursor-pointer"
    >
      <div
        className="bg-gray-900 text-white p-2 rounded-lg text-sm px-6 flex items-center gap-2"
        onClick={onOpen}
      >
        <FaPlusCircle /> Ajouter Annonce
      </div>

      <Modal isOpen={isOpen} onClose={onClose} placement="center">
        <ModalContent>
          <ModalHeader className='text-center'>Ajouter Annonce</ModalHeader>
          <ModalBody>
            <ul className="flex flex-col space-y-4">
              {menuItems.map((item) => (
                <Button
                  key={item.id}
                  onClick={() => handleItemClick(item.path)}
                  className="hover:text-[#64748b] text-sm font-semibold cursor-pointer flex items-center gap-2" size='lg'
                >
                  {item.icon}
                  <span>{item.name}</span>
                </Button>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </motion.div>
  );
};

export default Menu;