import { createSlice } from '@reduxjs/toolkit';

export const AppSlice = createSlice({
  name: 'app',
  initialState: {
    property_type: null,
    location: null,
    category: null,
    bed_number: null,
    budget: null,
    service_type: null,
    isSent:false,
    filePreviews: [],
    selectedImage: null,
  },
  reducers: {
    setProperty_type: (state, action) => {
      state.property_type = action.payload;
    },

    setSelectedImage: (state, action) => {
      state.selectedImage = action.payload;
    },
    setIsSent: (state, action) => {
      state.isSent = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },

    setCategory: (state, action) => {
      state.category = action.payload;
    },

    setBed_number: (state, action) => {
      state.bed_number = action.payload;
    },
    setBudget: (state, action) => {
      state.budget = action.payload;
    },

    setService_type: (state, action) => {
      state.service_type = action.payload;
    },

    setFilePreviews: (state, action) => {
      state.filePreviews = action.payload;
    },
    resetParams: (state) => {
      state.property_type = null;
      state.location = null;
      state.category = null;
      state.bed_number = null;
      state.budget = null;
      state.service_type = null;
    },
  },
});

export const {
  setProperty_type,
  setIsSent,
  resetParams,
  setLocation,
  setCategory,
  setBed_number,
  setBudget,
  setService_type,
  setFilePreviews,
  setSelectedImage
} = AppSlice.actions;
export const searchParams = (state: any) => state.app?.searchParams;
export const _location = (state: any) => state.app?.location;
export const _property_type = (state: any) => state.app?.property_type;
export const _category = (state: any) => state.app?.category;
export const _bed_number = (state: any) => state.app?.bed_number;
export const _budget = (state: any) => state.app?.budget;
export const _service_type = (state: any) => state.app?.service_type;
export const _filePreviews = (state: any) => state.app?.filePreviews;
export const _isSent = (state: any) => state.app?.isSent;
export const _selectedImage = (state: any) => state.app?.selectedImage;

export default AppSlice.reducer;
