import { setIsSent } from '@/app/providers/slices/AppSlice';
import { AppDispatch } from '@/app/providers/store';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@heroui/react";
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import db from '../../../../firebaseConfig';
// import firebase from "firebase/app";
import { useState } from 'react';
import * as Yup from 'yup';

export interface IContactInfoModalProps {
    isOpen: boolean;
    product: any;
    onClose?: () => void;
}

export default function ContactInfoModal(props: IContactInfoModalProps) {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const date = new Date();

    // Validation schema for the form
    const ContactInfoValidation = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-ZÀ-ÿ\s-]+$/, 'Le nom doit contenir uniquement des lettres.')
            .required('Veuillez entrer votre nom.'),
        phone: Yup.string()
            .matches(/^\+\d{9,15}$/, 'Veuillez entrer un numéro valide au format international (+243...).')
            .required('Veuillez entrer votre numéro de téléphone.')
    });

    const initialValues = {
        name: '',
        phone: '',
        seller: '',
        product: '',
        product_price: '',
        product_subcategory: '',
        listingNumber: '',
        created_at: '',
    };

    const handleSend = async (values: any) => {
        setIsLoading(true);
        values.seller = props.product?.seller?.email;
        values.product = props.product?.name;
        values.product_price = props.product?.price;
        values.listingNumber = props.product?.listingNumber;
        values.product_subcategory = props.product?.subcategory?.name;
        values.created_at = date;

        const newRef = db.ref("client").push();
        await newRef.set(values);

        setIsLoading(false);
        dispatch(setIsSent(true));
    };

    return (
        <Modal
            size={'sm'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            placement='center'
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Reserver l'article</ModalHeader>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={ContactInfoValidation}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(false);
                                handleSend(values);
                            }}
                        >
                            {(props) => (
                                <>
                                    <ModalBody>
                                        <div className="flex flex-col">
                                            <Input
                                                type="text"
                                                className={`text-black text-center rounded-md ${props.errors.name && props.touched.name ? 'border-red-500' : ''}`}
                                                name="name"
                                                placeholder="Votre nom"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name}
                                                required
                                            />
                                            {props.errors.name && props.touched.name && (
                                                <small className="text-red-500 text-sm mt-1">{props.errors.name}</small>
                                            )}

                                            <Input
                                                type="tel"
                                                className={`text-black text-center rounded-md mt-4 ${props.errors.phone && props.touched.phone ? 'border-red-500' : ''}`}
                                                name="phone"
                                                placeholder="+243987684434"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.phone}
                                                required
                                            />
                                            {props.errors.phone && props.touched.phone && (
                                                <small className="text-red-500 text-sm mt-1">{props.errors.phone}</small>
                                            )}
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            type="submit"
                                            isLoading={isLoading}
                                            color="default"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent default button behavior if needed
                                                props.handleSubmit(); // Call the submit function
                                            }}
                                        >
                                            Reserver
                                        </Button>
                                    </ModalFooter>
                                </>
                            )}
                        </Formik>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}