export const tabTexts = {
    property: {
        title: 'Trouvez Votre Maison de Rêve',
        subtitle:
            'Explorez les meilleures propriétés en un clic. Commencez dès maintenant',
        backgroundImage: [
            "url('/images/properties/property1.png')",
            "url('https://images.unsplash.com/photo-1564013799919-ab600027ffc6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        ]

    },

    services: {
        title: 'Services Fiables, Vie Simplifiée',
        subtitle:
            'Découvrez des prestataires triés sur le volet. Facilitez votre quotidien',
        backgroundImage: [
            "url('/images/serviceL/service1.png')",
            "url('/images/serviceL/service2.png')",
            "url('/images/serviceL/service3.png')",
            "url('/images/serviceL/service4.png')",

        ]
        // "url('https://images.unsplash.com/reserve/oIpwxeeSPy1cnwYpqJ1w_Dufer%20Collateral%20test.jpg?q=80&w=1915&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
    },
    cars: {
        title: 'Véhicules Dignes de Confiance',
        subtitle:
            'Trouvez le véhicule idéal en quelques clics. Simplifiez vos déplacements !',
        backgroundImage: [
            "url('/images/cars/car1.png')",
            "url('/images/cars/car2.png')",
            "url('/images/cars/car3.png')",
        ]
        // "url('https://images.unsplash.com/photo-1541443131876-44b03de101c5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
    },

    products: {
        title: 'Produits Dignes de Confiance',
        subtitle:
            'Explorez une sélection exceptionnelle de produits en toute simplicité!',
        backgroundImage: [
            // "url('/images/products/shopping1.png')",
            "url('/images/products/shopping2.png')",
            "url('/images/products/shopping4.png')",
        ]
    },
};