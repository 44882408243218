'use client';

import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Quote } from 'lucide-react';
import Image from "next/image";

interface ITestimony {
    id: string | number;
    text: string;
    image: {
        url: string;
    };
    title: string;
    person_name: string;
}

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState<ITestimony[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiKey = process.env.NEXT_PUBLIC_API_KEY;
                const apiUrl = process.env.NEXT_PUBLIC_API_URL;
                const response = await fetch(`${apiUrl}testimonials?populate=*`, {
                    headers: {
                        'Authorization': `Bearer ${apiKey}`,
                        'Content-Type': 'application/json'
                    }
                });
                const jsonData = await response.json();
                setTestimonials(jsonData.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (testimonials.length === 0) return;
        const interval = setInterval(() => {
            setActiveIndex((current) => (current + 1) % testimonials.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [testimonials.length]);

    const handleNavigation = (direction: 'prev' | 'next') => {
        setActiveIndex((current) => {
            if (direction === 'next') return (current + 1) % testimonials.length;
            return (current - 1 + testimonials.length) % testimonials.length;
        });
    };

    return (
        <section className="py-10 bg-gradient-to-b from-gray-50 to-white">
            <div className="px-4 mx-auto max-w-6xl sm:px-6 lg:px-8">
                <div className="flex flex-col items-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-3xl font-heading">
                        Ce que disent nos clients satisfaits
                    </h2>
                    <div className="mt-3 h-1 w-20 bg-orange-500 rounded-full"></div>
                    <p className="mt-4 text-lg text-gray-600 text-center max-w-2xl">
                        Des centaines de personnes ont exprimé leur satisfaction à propos de CasaKonnect
                    </p>
                </div>

                <div className="relative mt-8">
                    {/* Carousel Container */}
                    <div className="relative mx-auto max-w-4xl bg-white rounded-2xl shadow-lg p-8 overflow-hidden">
                        <Quote className="absolute top-6 left-6 w-12 h-12 text-orange-100 opacity-50" />

                        <div className="relative h-[280px] overflow-hidden">
                            {testimonials.map((testimonial, idx) => (
                                <div
                                    key={testimonial.id}
                                    className={`absolute w-full transition-transform duration-700 ease-in-out ${idx === activeIndex
                                            ? 'opacity-100 translate-x-0'
                                            : idx < activeIndex
                                                ? 'opacity-0 -translate-x-full'
                                                : 'opacity-0 translate-x-full'
                                        }`}
                                    style={{ height: '100%' }}
                                >
                                    <div className="flex flex-col items-center h-full justify-center">
                                        <div className="w-20 h-20 relative mb-4 ring-4 ring-orange-50">
                                            <Image
                                                src={testimonial.image?.url || '/profilepicture.png'}
                                                alt={testimonial.person_name}
                                                fill
                                                className="rounded-full object-cover"
                                            />
                                        </div>
                                        <div className="flex gap-1 mb-4">
                                            {[...Array(5)].map((_, index) => (
                                                <svg
                                                    key={index}
                                                    className="w-5 h-5 text-yellow-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                            ))}
                                        </div>
                                        <p className="text-xl md:text-2xl text-gray-800 text-center font-medium mb-6 italic">
                                            "{testimonial.text}"
                                        </p>
                                        <div className="text-center">
                                            <p className="font-semibold text-gray-900">{testimonial.person_name}</p>
                                            <p className="text-sm text-gray-600 mt-1">{testimonial.title}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Navigation Controls */}
                        <div className="flex items-center justify-between mt-8">
                            <button
                                onClick={() => handleNavigation('prev')}
                                className="p-2 rounded-full bg-black hover:bg-orange-100 transition-colors group"
                                aria-label="Previous testimonial"
                            >
                                <ChevronLeft className="w-6 h-6 text-white group-hover:text-orange-700" />
                            </button>
                            <div className="flex gap-2">
                                {testimonials.map((_, idx) => (
                                    <button
                                        key={idx}
                                        onClick={() => setActiveIndex(idx)}
                                        className={`w-2.5 h-2.5 rounded-full transition-colors ${idx === activeIndex ? 'bg-orange-500' : 'bg-orange-200'
                                            }`}
                                        aria-label={`Go to testimonial ${idx + 1}`}
                                    />
                                ))}
                            </div>
                            <button
                                onClick={() => handleNavigation('next')}
                                className="p-2 rounded-full bg-black hover:bg-orange-100 transition-colors group"
                                aria-label="Next testimonial"
                            >
                                <ChevronRight className="w-6 h-6 text-white group-hover:text-orange-700" />
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Testimonials;