'use client';
import { Button } from "@heroui/react";
import UseRegisterModal from '@/app/hooks/useRegisterModal';

const RegisterComponent = () => {
  const registerModal = UseRegisterModal();

  const handleOpenRegisterModal = () => {
    registerModal.onOpen();
  };

  return (
    <div>
      <Button onClick={handleOpenRegisterModal} size='lg' color='danger' className='font-bold'>S'inscrire</Button>
    </div>
  );
};

export default RegisterComponent;
