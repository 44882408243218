import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const ReactSelect = dynamic(() => import('react-select'), { ssr: false });

export interface ICustomSelectProps {
  optionData: any;
  handleChange?: any;
  onBlur?: any;
  name?: string;
  value?: any;
  placeholder?: string;
  isMulti?: boolean;
}

export default function CustomSelect(props: ICustomSelectProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const Options = props?.optionData?.map((option: any) => ({
    id: option?.id,
    name: option.type ? option.type : option.name,
    icon: option?.icon,
  }));

  const getOptionLabel = (option: any) => option.name;
  const getOptionValue = (option: any) => option.id;

  return (
    <>
      {isClient ? (
        <ReactSelect
          instanceId={uuidv4()}
          isMulti={props.isMulti}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          options={Options}
          name={props.name}
          onBlur={props.onBlur}
          onChange={props.handleChange}
          value={
            props.value
              ? (!props.isMulti
                ? Options.find((option: any) => option.id === props.value.id)
                : props.value
              )
              : null
          }
          placeholder={
            <div className=" text-gray-400 text-sm ">{props.placeholder}</div>
          }
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              //   borderColor: state.isFocused ? "grey" : "#9AA7B3",
              borderWidth: 1,
              borderRadius: 10,
              padding: 3,
              color: '#9AA7B3',
            }),
          }}
        />
      ) : (
        // Render a fallback component or placeholder for server-side rendering 
        <div className="text-gray-400 text-sm">{props.placeholder}</div>
      )}
    </>
  );
}
